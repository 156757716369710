<template>
  <div>
    <v-container fluid>

      <v-overlay :value="overlay" opacity="0">
        <v-progress-circular
            color="primary"
            indeterminate
            size="50"
        ></v-progress-circular>
      </v-overlay>

      <div class="main">

        <v-card class="shadow">
          <v-card-text class="pa-0">
            <v-row no-gutters>

              <v-col cols="12" lg="3" style="border-right: 1px solid #eaeaea">
                <v-card flat>

                  <v-card-text v-if="recuperatorsLoading">
                    <v-skeleton-loader v-for="el in 4" :key="el" type="list-item-avatar-two-line"/>
                  </v-card-text>

                  <v-card-text v-else class="pa-0">


                    <v-card outlined class="rounded mx-3 my-5">
                      <h5 class="font-weight-medium pa-3 grey--text text--darken-1">
                        Recouvreurs
                      </h5>

                      <vue-scroll :ops="ops" :style="{height: '400px'}">
                        <div @mouseleave="mouseLeave" @mouseover="mouseOver">
                      <v-list nav>
                        <v-list-item-group v-if="recuperators.length"
                                           v-model="recuperatorId"
                                           color="primary"
                                           mandatory>
                          <v-list-item
                              v-for="(recuperator, i) in recuperators"
                              :key="i"
                              :value="recuperator.id">
                            <v-list-item-avatar size="32">
                              <v-img :src="require('@/assets/avatar.png')"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title v-text="recuperator.name"></v-list-item-title>
                              <v-list-item-subtitle v-if="can('admin | order_manager')"
                                                    class="primary--text">
                                <v-icon color="primary" size="10">mdi-circle</v-icon>
                                {{ recuperator.supplier ? recuperator.supplier.name : '-' }}
                              </v-list-item-subtitle>

                            </v-list-item-content>
                          </v-list-item>
                        </v-list-item-group>

                      </v-list>
                        </div>
                      </vue-scroll>

                    </v-card>


                    <v-card outlined class="rounded mx-3">
                      <h5 class="font-weight-medium pa-3 grey--text text--darken-1">
                        Menu
                      </h5>

                      <v-list nav>

                        <v-list-item v-if="can('admin | order_manager')" to="/recoveries/dashboard">
                          <v-list-item-avatar size="32">
                            <v-icon>mdi-view-dashboard-outline</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>Dashboard</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item to="/recoveries/recuperators">
                          <v-list-item-avatar size="32">
                            <v-icon>mdi-account-group-outline</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>Recouvreurs</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="can('admin | order_manager')" to="/recoveries/payments">
                          <v-list-item-avatar size="32">
                            <v-icon>mdi-cash-multiple</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>Paiements</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item v-if="can('admin | order_manager')" to="/recoveries/config">
                          <v-list-item-avatar size="32">
                            <v-icon>mdi-cog-outline</v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>Configuration</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                      </v-list>

                    </v-card>


                  </v-card-text>

                </v-card>
              </v-col>

              <v-col cols="12" lg="9">

                <div class="pa-2">
                  <StatisticCards ref="statisticCards"
                                  :loading="listLoading || recuperatorsLoading"
                                  :recuperator-id="recuperatorId"
                                  :statistics="statistics"
                                  class="mb-3"
                                  @is-today="[isToday = true,getRecoveries(),$vuetify.goTo('#table')]"/>
                </div>

                <div class="d-block d-lg-flex  justify-space-between pa-3">

                  <v-btn class="rounded-lg text-none" color="grey" text>
                    <v-icon color="success" small>mdi-circle</v-icon>
                    Validé
                  </v-btn>

                  <v-btn class="my-lg-0 my-2 rounded-lg text-none" color="grey" text>
                    <v-icon color="warning" small>mdi-circle</v-icon>
                    En attente
                  </v-btn>


                  <v-spacer/>

                  <v-btn color="gifty"
                         dark
                         depressed
                         text
                         @click="showSalepoints">
                    <v-icon left>mdi-chart-box-outline</v-icon>
                    Situation des crédits
                  </v-btn>

                  <v-btn color="gifty"
                         dark
                         depressed
                         text
                         @click="addRecovery">
                    <v-icon left size="22">mdi-cash-plus</v-icon>
                    Recouvrement
                  </v-btn>

                  <v-btn color="gifty"
                         dark
                         depressed
                         @click="addPayment">
                    <v-icon left>mdi-plus</v-icon>
                    Paiement
                  </v-btn>
                </div>

                <v-divider/>

                <v-card id="table" flat>

                  <v-card-title>

                    <div>
                      <v-text-field
                          v-model="keyword"
                          append-icon="mdi-magnify"
                          clearable
                          dense
                          filled
                          hide-details
                          placeholder="Recherche..."
                          rounded
                          single-line
                      ></v-text-field>
                    </div>

                    <v-spacer/>

                    <v-btn color="grey" text @click="$refs.filterDialog.open()">
                      <v-icon left>mdi-filter-outline</v-icon>
                      Filtre
                    </v-btn>

                    <v-btn :class="isToday ? 'v-btn--active' : ''"
                           :color="isToday ? 'primary' : 'grey'"
                           text
                           @click="[isToday = !isToday,getRecoveries()]">
                      <v-icon left>mdi-clock-check-outline</v-icon>
                      Aujourd'hui
                    </v-btn>

                    <v-btn color="grey"
                           icon
                           @click="[dates=[],status=null,raisonIds=[],getRecoveries()]">
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>

                  </v-card-title>

                  <v-divider/>

                  <v-card-text v-if="listLoading || recuperatorsLoading">
                    <v-skeleton-loader type="table"/>
                  </v-card-text>

                  <div v-else>

                    <v-card-text class="pa-0">

                      <v-simple-table v-if="recoveries.length">
                        <template v-slot:default>
                          <thead>
                          <tr>
                            <th></th>
                            <th>POS</th>
                            <th>Commune</th>
                            <th class="text-center">Ancien montant</th>
                            <th class="text-center">Montant payé</th>
                            <th class="text-center">Écart</th>
                            <th class="text-center">Service</th>
                            <th>Date de recouvrement</th>
                            <th>Source</th>
                            <th v-if="can('admin | order_manager')"></th>
                          </tr>
                          </thead>
                          <tbody>

                          <tr v-for="(item, i) in recoveries" :key="i" class="pointer" title="Cliquez pour afficher plus de détails">

                            <td @click="$refs.detailsDialog.open(item)">
                              <v-icon v-if="item.status === 'validated'" color="success">mdi-check-circle</v-icon>
                              <v-icon v-else color="warning">mdi-timer-sand</v-icon>
                            </td>

                            <td @click="$refs.detailsDialog.open(item)" class="text-no-wrap">
                              {{ item.account.user.name }}
                            </td>

                            <td @click="$refs.detailsDialog.open(item)" class="text-no-wrap">
                              {{ item.account?.salepoint?.commune?.name }}
                            </td>

                            <td class="text-center" @click="$refs.detailsDialog.open(item)">
                              <div class="font-weight-medium">
                                {{ CurrencyFormatting(item.old_amount) }}
                              </div>
                            </td>

                            <td class="text-center" @click="$refs.detailsDialog.open(item)">
                              <div class="gifty--text font-weight-medium">
                                {{ CurrencyFormatting(item.paid_amount) }}
                              </div>
                            </td>

                            <td class="text-center" @click="$refs.detailsDialog.open(item)">
                              <div class="info--text font-weight-medium text-no-wrap">
                                {{
                                  (item.paid_amount - item.old_amount) > 0 ? '+' :
                                      (item.paid_amount - item.old_amount) === 0 ? '' : '-'
                                }}{{ CurrencyFormatting(Math.abs(item.old_amount - item.paid_amount)) }}
                              </div>
                            </td>

                            <td>
                              {{ item.service?.name }}
                            </td>

                            <td class="text-no-wrap" @click="$refs.detailsDialog.open(item)">
                              {{ dateFormat(item.recovery_at) }}
                            </td>

                            <td class="text-center" @click="$refs.detailsDialog.open(item)">
                              <v-avatar color="info" size="30">
                                <v-icon v-if="item.source === 'mobile'" dark small>mdi-cellphone</v-icon>
                                <v-icon v-else dark small>mdi-desktop-mac</v-icon>
                              </v-avatar>
                            </td>

                            <td>
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn v-if="item.status === 'pending'" icon v-bind="attrs" v-on="on" @click="remove(item.id)">
                                    <v-icon>mdi-delete-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span>Supprimer</span>
                              </v-tooltip>
                            </td>

                          </tr>

                          </tbody>
                        </template>
                      </v-simple-table>

                      <div v-else>
                        <NoResults @reload="getRecoveries"/>
                      </div>

                    </v-card-text>

                    <v-divider v-if="total > 10"/>

                    <v-card-actions v-if="total > 10">

                      <div :style="{width: '120px'}">
                        <v-select v-model="perPage"
                                  :items="[10,20,30,50,100]"
                                  dense
                                  hide-details
                                  label="Ligne par page"
                                  outlined></v-select>
                      </div>

                      <v-spacer/>

                      <v-pagination v-model="pagination.current"
                                    :length="pagination.total"
                                    circle
                                    total-visible="8"
                                    @input="getRecoveries"></v-pagination>

                    </v-card-actions>

                  </div>


                </v-card>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>


      </div>

      <AddPaymentDialog ref="addPaymentDialog" @refresh="getRecoveries"/>
      <AddRecoveryDialog ref="addRecoveryDialog" @refresh="getRecoveries"/>
      <SalepointsDialog ref="salepointsDialog"/>
      <FilterDialog ref="filterDialog" @filter="handleFilter"/>
      <DetailsDialog ref="detailsDialog"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResults from "@/components/NoResults";
import SalepointsDialog from "./components/SalepointsDialog";
import AddPaymentDialog from "./components/AddPaymentDialog";
import AddRecoveryDialog from "./components/AddRecoveryDialog";
import FilterDialog from "./components/FilterDialog";
import StatisticCards from "@/views/recoveries/components/StatisticCards";
import DetailsDialog from "@/views/recoveries/components/DetailsDialog";
import moment from "moment";

export default {
  components: {
    DetailsDialog,
    StatisticCards,
    NoResults,
    AddPaymentDialog,
    FilterDialog,
    AddRecoveryDialog,
    SalepointsDialog
  },
  data() {
    return {
      recuperatorsLoading: false,
      listLoading: false,
      overlay: false,
      recuperators: [],

      dates: [],
      status: null,
      keyword: null,
      isToday: false,
      raisonIds: [],

      recuperatorId: null,
      total: 0,
      perPage: 10,
      pagination: {
        current: 1,
        total: 0
      },
      recoveries: [],
      id: null,

      statistics: {},
      ops: {
        bar: {
          opacity: 0.8,
          background: "#d60c56",
          size: '6px',
          keepShow: false,

        },
        scrollPanel: {
          scrollingX: false,
        }
      },
    };
  },
  methods: {
    mouseOver() {
      this.ops.bar.keepShow = true
    },
    mouseLeave() {
      this.ops.bar.keepShow = false
    },
    getRecuperators() {
      this.recuperatorsLoading = true
      HTTP.get('/v1/recoveries/recuperators').then(res => {
        this.recuperatorsLoading = false
        let recuperators = res.data.data

        if (recuperators.length) {
          this.recuperators = recuperators
          this.recuperatorId = recuperators[0].id
          this.getRecoveries()
        }

      }).catch(err => {
        this.recuperatorsLoading = false
        console.log(err)
      })
    },

    getRecoveries() {
      this.listLoading = true
      HTTP.get('/v1/recoveries?page=' + this.pagination.current, {
        params: {
          recuperatorId: this.recuperatorId,
          isToday: this.isToday,
          perPage: this.perPage,
          keyword: this.keyword,
          status: this.status,
          raisonIds: this.raisonIds,
          dates: this.dates,
        }
      }).then(res => {
        this.pagination.current = res.data.data.current_page;
        this.pagination.total = res.data.data.last_page;
        this.total = res.data.data.total;

        this.recoveries = res.data.data.data
        this.statistics = res.data.statistics

        this.listLoading = false
      }).catch(err => {
        this.listLoading = false
        console.log(err)
      })
    },
    addPayment() {
      this.$refs.addPaymentDialog.open(this.recuperatorId)
    },
    addRecovery() {
      this.$refs.addRecoveryDialog.open(this.recuperatorId)
    },
    showSalepoints() {
      this.$refs.salepointsDialog.open(this.recuperatorId)
    },
    handleFilter(e) {
      this.dates = e.dates
      this.status = e.status
      this.raisonIds = e.raisonIds
      this.getRecoveries()
    },
    remove(id) {
      this.id = id;
      this.$confirm_dialog = true;
    },
    deleteItem() {
      this.overlay = true;

      HTTP.post("/v1/recoveries/delete", {
        recovery_id: this.id
      }).then(() => {
        this.overlay = false;
        let i = this.recoveries.findIndex((el) => el.id === this.id);
        this.$delete(this.recoveries, i);
        this.$successMessage = 'Ce recouvrement a été supprimé avec succès.'
        this.id = null;
      }).catch((err) => {
        this.overlay = false;
        console.log(err);
      });
    },
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY, HH:mm');
    }
  },
  watch: {
    recuperatorId() {
      this.getRecoveries()
    },
    perPage() {
      this.pagination.current = 1
      this.getRecoveries()
    },
    keyword() {
      this.getRecoveries()
    },
    status() {
      this.getRecoveries()
    },
    $is_confirm: function (el) {
      if (el) {
        this.deleteItem();
        this.$is_confirm = false;
      }
    },

  },

  created() {
    this.getRecuperators();
  },
};
</script>

<style scoped></style>
